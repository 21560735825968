@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .flex-center {
    @apply flex justify-center items-center;
  }
  
  .row-card {
    @apply p-[30px] rounded-[8px] shadow-[0_5px_35px_0_rgba(30,34,40,0.07)];
  }
  
  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-none {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  /* Адаптивные заголовки */
  .text-display-1 {
    @apply text-[48px] leading-[52px] font-bold sm:text-[90px] sm:leading-[90px];
  }
  
  .text-display-2 {
    @apply text-[36px] leading-[44px] font-bold sm:text-[72px] sm:leading-[80px];
  }
  
  .text-display-3 {
    @apply text-[28px] leading-[34px] font-bold sm:text-[44px] sm:leading-[50px];
  }
  
  .text-display-4 {
    @apply text-[22px] leading-[30px] font-bold sm:text-[28px] sm:leading-[38px];
  }
  
  .text-heading-1 {
    @apply text-[32px] leading-[40px] font-bold sm:text-[56px] sm:leading-[66px];
  }
  
  .text-heading-2 {
    @apply text-[28px] leading-[36px] font-bold sm:text-[36px] sm:leading-[46px];
  }
  
  .text-heading-3 {
    @apply text-[22px] leading-[30px] font-bold sm:text-[24px] sm:leading-[34px];
  }
  
  .text-heading-4 {
    @apply text-[20px] leading-[26px] font-bold sm:text-[22px] sm:leading-[28px];
  }
  
  .text-heading-5 {
    @apply text-[16px] leading-[22px] font-bold sm:text-[18px] sm:leading-[24px];
  }
  
  .text-heading-6 {
    @apply text-[14px] leading-[20px] font-bold sm:text-[16px] sm:leading-[22px];
  }
  
  .text-body-large {
    @apply text-[18px] leading-[28px] font-normal sm:text-[22px] sm:leading-[36px];
  }
  
  .text-body-default {
    @apply text-[16px] leading-[22px] font-normal sm:text-[18px] sm:leading-[24.6px];
  }
  
  .text-body-small {
    @apply text-[14px] leading-[18px] font-normal sm:text-[16px] sm:leading-[21.9px];
  }
  
  .text-body-smaller {
    @apply text-[12px] leading-[16px] font-normal sm:text-[14px] sm:leading-[16px];
  }
  
  .text-400 {
    @apply text-[18px] leading-[22px] sm:text-[22px] sm:leading-[22px];
  }
  
  .text-300 {
    @apply text-[16px] leading-[20px] sm:text-[20px] sm:leading-[20px];
  }
  
  .text-200 {
    @apply text-[16px] leading-[22px] sm:text-[18px] sm:leading-[24.6px];
  }
  
  .text-100 {
    @apply text-[14px] leading-[18px] sm:text-[16px] sm:leading-[18px];
  }
  
  .text-bold {
    @apply text-[16px] leading-[22px] font-bold sm:text-[18px] sm:leading-[24.6px];
  }
  
  .text-link {
    @apply text-[16px] text-accent leading-[22px] font-bold underline sm:text-[18px] sm:leading-[24.6px];
  }
}

html {
  font-family: 'Manrope', sans-serif;
  box-sizing: border-box;
  @apply text-[16px] leading-[22px] font-normal sm:text-[18px] sm:leading-[24.6px];
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1,h2,h3,h4,h5,h6 {
  @apply text-heading font-bold;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.phone-input-wrapper input {
  @apply w-full text-body-small rounded-[20px] px-[16px] h-[56px] sm:h-[66px] border outline-none border-secondary;
}
