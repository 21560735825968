.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-active {
  background-color: #006cfb;
  color: white;
  border-radius: 8px;
  height: 44px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-inactive {
  opacity: 0.5;
  background-color: #006cfb;
  color: white;
  border-radius: 8px;
  height: 44px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation 0.5s ease-in-out forwards;
}

.scale-fade-in {
  transform: scale(0.7);
  animation: scaleFadeInAnimation 0.3s ease-in-out forwards;
}

.animation-fast {
  animation-duration: 300ms;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleFadeInAnimation {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.property-page-layout {
  display: flex;
  gap: 30px;
  margin-top: 20px;
  
  @media (max-width: 1500px) {
    flex-direction: column;
  }
}
